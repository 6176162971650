import React from 'react';
import Style from './style.module.scss';
import Logo from '../../assets/images/partner_logo/One-assure-logo.svg';
import CallIcon from '../../assets/images/call-grey.svg';
import MailIcon from '../../assets/images/mail-grey.svg';
import IrdaiLogo from '../../assets/images/partner_logo/IRDA_Logo.svg';
import PciDss from '../../assets/images/partner_logo/pci-dss-logo.svg';
import { Nav } from 'react-bootstrap';

const Footer = () => {
    return (
        <div className={Style.main}>
            <div className={Style.topFooter}>
                <div className={Style.footerSection}>
                    <div className={Style.title}>Secured With</div>
                    <img
                        style={{ height: '75px', width: '96px' }}
                        src={IrdaiLogo}
                        alt=""
                    />
                    <img
                        style={{ height: '75px', width: '96px' }}
                        src={PciDss}
                        alt=""
                    />
                </div>
                <div className={Style.footerSection}>
                    <div className={Style.footerIcon}>
                        <img src={MailIcon} alt="" />
                        <Nav.Link
                            className={Style.contact}
                            href="mailto:hello@covrzy.com"
                        >
                            hello@covrzy.com
                        </Nav.Link>
                    </div>
                    <div className={Style.footerIcon}>
                        <img src={CallIcon} alt="" />
                        <Nav.Link
                            className={Style.contact}
                            href="tel:9354963947"
                        >
                            +(91)-9354963947
                        </Nav.Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
