// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__kioOn {
  display: flex;
  align-items: center;
  gap: 13px;
}
.style_main__kioOn img {
  height: 24px;
  width: 24px;
}
.style_main__kioOn div {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .style_main__kioOn div {
    line-height: 22px;
    font-size: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SecurityCard/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;AAER;AAAI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAER;AADQ;EAPJ;IAQQ,iBAAA;IACA,eAAA;EAIV;AACF","sourcesContent":[".main {\n    display: flex;\n    align-items: center;\n    gap: 13px;\n    img {\n        height: 24px;\n        width: 24px;\n    }\n    div {\n        font-size: 16px;\n        font-weight: 500;\n        line-height: 16px;\n        letter-spacing: 0em;\n        text-align: left;\n        color: #ffffff;\n        @media screen and (max-width: 767px) {\n            line-height: 22px;\n            font-size: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__kioOn`
};
export default ___CSS_LOADER_EXPORT___;
