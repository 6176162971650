import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { clarity } from 'clarity-js';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import Header from '../common/Layouts/Header';
import Footer from '../common/Layouts/Footer';
import Main from './Main';
import CustomToast from '../common/CustomToast';
import { IReduxState } from '../utils/types';
import { DecryptData } from '../common/CryptoJSToken';
import { initializeGAUser } from '../utils/googleAnalytics';

import AdminLogin from './AdminLogin/AdminLogin';
import { GetUserAuthData, LogoutAction, SetChannelAction } from '../Redux/Actions/AuthActions';
// import useMSG90OTPWidget from '../common/QuoteLayout/MSG90widgetfunction';
import ErrorHandlerModal from './ErrorHandlerModal';
import SMERoutes from './SMERoutes';
import SMELogin from './SMEFireInsurance/SMELoginModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { ChangeLanguageAction } from '../Redux/Actions/PreferenceActions';
import { ELanguagesOptions } from '../Redux/Reducers/PreferenceReducer';
import Cookies from 'js-cookie';
import { SaveUserStateAction } from '../Redux/Actions/SessionAction';

function App() {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    // const { handleSubmit } = useMSG90OTPWidget();
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const ToastState = useSelector((state: IReduxState) => state.Toast);
    const AuthState = useSelector((state: IReduxState) => state.Auth);

    const Preference = useSelector((state: IReduxState) => state.Preference);
    const { i18n } = useTranslation();
    const storedLanguage = localStorage.getItem('language')

    useLayoutEffect(() => {
        if (storedLanguage && storedLanguage !== i18n.language) {
            dispatch(ChangeLanguageAction(storedLanguage as keyof ELanguagesOptions));
        }
        else if (storedLanguage) {
            dispatch(ChangeLanguageAction(storedLanguage as keyof ELanguagesOptions));
        }
        else {
            dispatch(ChangeLanguageAction((ELanguagesOptions.english as String) as keyof ELanguagesOptions));
        }
    }, [i18n]);

    useLayoutEffect(() => {
        i18n.changeLanguage(Preference?.language);
    }, [i18n, Preference?.language]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        try {
            if (!AuthState || typeof AuthState.user !== 'string') return;

            const user = DecryptData(AuthState.user);

            if (process.env.REACT_APP_CLARITY_ID) {
                clarity.consent();
                if (user?.id) clarity.set('userId', user.id?.toString());
                clarity.start({
                    projectId: process.env.REACT_APP_CLARITY_ID,
                    upload: 'https://z.clarity.ms/collect',
                    track: true,
                    content: true,
                    cookies: ['userId'],
                });
            }
            if (!user) return;

            initializeGAUser({
                userId: user.id,
            });

            if (process.env.REACT_APP_CLARITY_ID) {
                clarity.set('userId', user.id?.toString());
            }
        } catch (err) {
            console.log(err);
        } finally {
            return () => {
                if (process.env.REACT_APP_CLARITY_ID) {
                    clarity.stop();
                }
            };
        }
    }, [AuthState, AuthState?.user]);

    useLayoutEffect(() => {
        const userToken = Cookies.get('userToken')
        const sessionId = sessionStorage.getItem('sessionId');
        if (!userToken && AuthState.isLogin) {
            dispatch(LogoutAction())
            localStorage.clear()
        }
        if (!AuthState.isLogin && userToken && !AuthState.loading && sessionId) {
            dispatch(SaveUserStateAction())
            dispatch(GetUserAuthData())
        }
        switch (window.location.host) {
            case 'beta.covrzy.com':
                try {
                    const authData = JSON.parse(
                        localStorage.getItem('EmployeeToken') ?? 'null',
                    );
                    if (!authData || authData?.expireTime <= Date.now()) {
                        localStorage.removeItem('EmployeeToken');
                        setIsLoggedIn(false);
                    }
                } catch (e) {
                    console.log('error in employee login', e);
                }

                break;
            default:
                break;
        }
    }, [AuthState]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const channel = params.get('channel');
        if (channel) {
            dispatch(SetChannelAction(channel));
        }
    }, [dispatch]);

    return (
        <Fragment>
            <HelmetProvider>
                {
                    window.location.host.includes("sme") || window.location.host.includes("test") ?
                        <SMERoutes />
                        :
                        <div>
                            {isLoggedIn ? (
                                <div className="App">
                                    {!(
                                        pathname?.includes('/user/') ||
                                        pathname?.includes('/quote/') ||
                                        pathname?.includes('/company/') ||
                                        window.location.host ===
                                        process.env.REACT_APP_PARTNER_DOMAIN
                                    ) ? (
                                        <>
                                            <Header />
                                            <Main />
                                            {!(
                                                pathname?.includes('sign-in') ||
                                                pathname?.includes('reset-password')
                                            ) && <Footer />}
                                        </>
                                    ) : (
                                        <Main />
                                    )}
                                </div>
                            ) : (
                                <AdminLogin onLogin={() => setIsLoggedIn(true)} />
                            )}
                        </div>
                }
                <SMELogin showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
                <CustomToast
                    show={ToastState.show}
                    message={ToastState.message}
                    severity={ToastState.severity}
                />
                <ErrorHandlerModal />
            </HelmetProvider>
        </Fragment>
    );
}

export default App;
