// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__gx0c6 {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
}
.style_main__gx0c6 .style_header__aDs5q h5 {
  color: #292c4e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentFeature/PaymentInfoCard/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACI;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AACN","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  border-radius: 10px;\n  background: #fff;\n  padding: 15px;\n  .header {\n    h5 {\n      color: #292c4e;\n      font-size: 20px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 160%; /* 32px */\n    }\n  }\n  .content {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__gx0c6`,
	"header": `style_header__aDs5q`
};
export default ___CSS_LOADER_EXPORT___;
