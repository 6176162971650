// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__dZ6r9 {
  display: flex;
  padding: 20px 31px;
  background: #f5f2fc;
  border-radius: 20px;
  flex-direction: column;
  gap: 15px;
}
.style_main__dZ6r9 h4 {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #7743dc;
}
.style_main__dZ6r9 .style_content__kQOUk {
  display: flex;
  gap: 65px;
  align-items: center;
  justify-content: center;
}
.style_main__dZ6r9 .style_content__kQOUk img {
  height: 79px;
  width: 75px;
}
.style_main__dZ6r9 .style_content__kQOUk p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.150000006px;
  text-align: left;
  color: #696b83;
}
.style_main__dZ6r9 button {
  box-shadow: 0px 6px 9px 0px rgba(54, 16, 129, 0.2);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: #7743dc;
  border-radius: 100px;
  border: none;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ThanksComponent/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AAER;AACI;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAAQ;EACI,YAAA;EACA,WAAA;AAEZ;AACQ;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,6BAAA;EACA,gBAAA;EACA,cAAA;AACZ;AAEI;EACI,kDAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAAR","sourcesContent":[".main {\n    display: flex;\n    padding: 20px 31px;\n    background: #f5f2fc;\n    border-radius: 20px;\n    flex-direction: column;\n    gap: 15px;\n    h4 {\n        font-family: Nunito;\n        font-size: 28px;\n        font-weight: 700;\n        line-height: 30px;\n        letter-spacing: 0em;\n        text-align: center;\n        color: #7743dc;\n    }\n\n    .content {\n        display: flex;\n        gap: 65px;\n        align-items: center;\n        justify-content: center;\n        img {\n            height: 79px;\n            width: 75px;\n        }\n\n        p {\n            font-family: Nunito;\n            font-size: 18px;\n            font-weight: 600;\n            line-height: 22px;\n            letter-spacing: 0.15000000596046448px;\n            text-align: left;\n            color: #696b83;\n        }\n    }\n    button {\n        box-shadow: 0px 6px 9px 0px #36108133;\n        font-size: 18px;\n        font-weight: 400;\n        letter-spacing: 0em;\n        text-align: center;\n        color: #ffffff;\n        background: #7743dc;\n        border-radius: 100px;\n        border: none;\n        padding: 10px 30px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        gap: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__dZ6r9`,
	"content": `style_content__kQOUk`
};
export default ___CSS_LOADER_EXPORT___;
