// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__-O\\+UX {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: var(--1, 8px);
  background: #f5f2fc;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
}
.style_main__-O\\+UX .style_header__q6hxi {
  display: flex;
  padding: var(--none, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--borderRadius, 4px);
  flex: 1 0;
}
.style_main__-O\\+UX .style_header__q6hxi h4 {
  color: #292c4e;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  letter-spacing: 0.15px;
}
.style_main__-O\\+UX .style_header__q6hxi p {
  color: #7743dc;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.style_main__-O\\+UX .style_log__el9Bg img {
  width: 144px;
  height: 32.969px;
  border-radius: var(--none, 0px);
}`, "",{"version":3,"sources":["webpack://./src/components/InsurerLogo/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,4BAAA;EACA,mBAAA;EACA,+CAAA;AACF;AAAE;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,SAAA;AAEJ;AADI;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,sBAAA;AAGN;AADI;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0BAAA;AAGN;AACI;EACE,YAAA;EACA,gBAAA;EACA,+BAAA;AACN","sourcesContent":[".main {\n  display: flex;\n  padding: 20px;\n  align-items: center;\n  gap: 20px;\n  align-self: stretch;\n  border-radius: var(--1, 8px);\n  background: #f5f2fc;\n  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);\n  .header {\n    display: flex;\n    padding: var(--none, 0px);\n    flex-direction: column;\n    align-items: flex-start;\n    gap: var(--borderRadius, 4px);\n    flex: 1 0 0;\n    h4 {\n      color: #292c4e;\n      font-family: Nunito;\n      font-size: 20px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 30px; /* 150% */\n      letter-spacing: 0.15px;\n    }\n    p {\n      color: #7743dc;\n      text-align: center;\n      font-family: Nunito;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: normal;\n      text-transform: capitalize;\n    }\n  }\n  .log {\n    img {\n      width: 144px;\n      height: 32.969px;\n      border-radius: var(--none, 0px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__-O+UX`,
	"header": `style_header__q6hxi`,
	"log": `style_log__el9Bg`
};
export default ___CSS_LOADER_EXPORT___;
