import { Action } from 'redux';

export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const GO_TO_PREV_STEP = 'GO_TO_PREV_STEP';
export const OPEN_GET_HELP_MODAL = 'OPEN_GET_HELP_MODAL';
export const CLOSE_GET_HELP_MODAL = 'CLOSE_GET_HELP_MODAL';
export const SET_STEP = 'SET_STEP';
export const RESET_STEP = 'RESET_STEP';
export const TAB_COMPLETED = 'TAB_COMPLETED';
export const DESIGN_DATA = 'DESIGN_DATA';
export const EXIT_CALLBACK_MODAL = 'EXIT_CALLBACK_MODAL';
export const SPECIAL_REQUIREMENT = 'SPECIAL_REQUIREMENT';
export const USER_DETAILS = 'USER_DETAILS';
export const UPDATE_QUOTATION_PURCHASE_METADATA =
    'UPDATE_QUOTATION_PURCHASE_METADATA';

export const RESET_MERCHANT_STEP_DETAILS = 'RESET_MERCHANT_STEP_DETAILS';

// API ACTIONS
export const GET_MERCHANT_STEP_REQUEST = 'GET_MERCHANT_STEP_REQUEST';
export const GET_MERCHANT_STEP_ERROR = 'GET_MERCHANT_STEP_ERROR';
export const GET_MERCHANT_STEP_SUCCESS = 'GET_MERCHANT_STEP_SUCCESS';

export const SET_MERCHANT_STEP_REQUEST = 'SET_MERCHANT_STEP_REQUEST';
export const SET_MERCHANT_STEP_ERROR = 'SET_MERCHANT_STEP_ERROR';
export const SET_MERCHANT_STEP_SUCCESS = 'SET_MERCHANT_STEP_SUCCESS';

export const MERCHANT_QUOTATION_REQUEST = 'MERCHANT_QUOTATION_REQUEST';
export const MERCHANT_QUOTATION_ERROR = 'MERCHANT_QUOTATION_ERROR';
export const MERCHANT_QUOTATION_SUCCESS = 'MERCHANT_QUOTATION_SUCCESS';

export const UPDATE_MERCHANT_QUOTATION_REQUEST =
    'UPDATE_MERCHANT_QUOTATION_REQUEST';
export const UPDATE_MERCHANT_QUOTATION_ERROR =
    'UPDATE_MERCHANT_QUOTATION_ERROR';
export const UPDATE_MERCHANT_QUOTATION_SUCCESS =
    'UPDATE_MERCHANT_QUOTATION_SUCCESS';

export const UPDATE_MERCHANT_QUOTATION_REQUEST2 =
    'UPDATE_MERCHANT_QUOTATION_REQUEST2';
export const UPDATE_MERCHANT_QUOTATION_ERROR2 =
    'UPDATE_MERCHANT_QUOTATION_ERROR2';
export const UPDATE_MERCHANT_QUOTATION_SUCCESS2 =
    'UPDATE_MERCHANT_QUOTATION_SUCCESS2';

export const CREATE_KYC_REQUEST = 'CREATE_KYC_REQUEST';
export const CREATE_KYC_ERROR = 'CREATE_KYC_ERROR';
export const CREATE_KYC_SUCCESS = 'CREATE_KYC_SUCCESS';

export const SAVE_ONBOARDING_FORM = 'SAVE_ONBOARDING_FORM';

export const SEND_OTP_PHONE_LOGIN_REQUEST: any = 'SEND_OTP_PHONE_LOGIN_REQUEST';
export const SEND_OTP_PHONE_LOGIN_SUCCESS: any = 'SEND_OTP_PHONE_LOGIN_SUCCESS';
export const SEND_OTP_PHONE_LOGIN_ERROR: any = 'SEND_OTP_PHONE_LOGIN_ERROR';

export const EDIT_OTP_PHONE_NUMBER: any = 'EDIT_OTP_PHONE_NUMBER';
export const EDIT_OTP_PHONE_NUMBER_CLEAR: any = 'EDIT_OTP_PHONE_NUMBER_CLEAR';

export const SEND_RESEND_OTP_PHONE_LOGIN_REQUEST: any =
    'SEND_RESEND_OTP_PHONE_LOGIN_REQUEST';
export const SEND_RESEND_OTP_PHONE_LOGIN_SUCCESS: any =
    'SEND_RESEND_OTP_PHONE_LOGIN_SUCCESS';
export const SEND_RESEND_OTP_PHONE_LOGIN_ERROR: any =
    'SEND_RESEND_OTP_PHONE_LOGIN_ERROR';

export const VERIFY_OTP_PHONE_LOGIN_REQUEST: any =
    'VERIFY_OTP_PHONE_LOGIN_REQUEST';
export const VERIFY_OTP_PHONE_LOGIN_ERROR: any = 'VERIFY_OTP_PHONE_LOGIN_ERROR';
export const VERIFY_OTP_PHONE_LOGIN_SUCCESS: any =
    'VERIFY_OTP_PHONE_LOGIN_SUCCESS';

export const USER_LOGOUT_RESET_SME_DETAILS: any =
    'USER_LOGOUT_RESET_SME_DETAILS';

export const CHANGE_INSURANCE_TYPE: any = 'CHANGE_INSURANCE_TYPE';

export interface GoToNextStepAction extends Action<typeof GO_TO_NEXT_STEP> {}
export interface GoToPrevStepAction extends Action<typeof GO_TO_PREV_STEP> {}
export interface OpenGetHelpModalAction
    extends Action<typeof OPEN_GET_HELP_MODAL> {}
export interface CloseGetHelpModalAction
    extends Action<typeof CLOSE_GET_HELP_MODAL> {}
export interface SetStepAction extends Action<typeof SET_STEP> {
    payload: number;
}
export interface ResetStepAction extends Action<typeof RESET_STEP> {}
export interface TabCompleted extends Action<typeof TAB_COMPLETED> {
    payload: number;
}
export interface DesignDataAction extends Action<typeof DESIGN_DATA> {
    payload: object;
}
export interface ExitCallbackModalAction
    extends Action<typeof EXIT_CALLBACK_MODAL> {}

export interface SpecialRequirementAction
    extends Action<typeof SPECIAL_REQUIREMENT> {}

export interface UserDetailsAction extends Action<typeof USER_DETAILS> {
    payload: object;
}

export type StepActionTypes =
    | GoToNextStepAction
    | GoToPrevStepAction
    | OpenGetHelpModalAction
    | CloseGetHelpModalAction
    | SetStepAction
    | ResetStepAction
    | TabCompleted
    | DesignDataAction
    | ExitCallbackModalAction
    | UserDetailsAction;

// Define actions
export const goToNextStep = () => {
    return { type: GO_TO_NEXT_STEP };
};

export const goToPrevStep = () => {
    return { type: GO_TO_PREV_STEP };
};

export const updateStep = (data: number) => {
    return { type: SET_STEP, payload: data };
};

export const openGetHelpModal = () => {
    return { type: OPEN_GET_HELP_MODAL };
};

export const closeGetHelpModal = () => {
    return { type: CLOSE_GET_HELP_MODAL };
};

export const tabCompleted = (data: number) => {
    return { type: TAB_COMPLETED, payload: data };
};

export const designData = (data: object) => {
    return { type: DESIGN_DATA, payload: data };
};

export const saveMerchantQuotation = (data: { [key: string]: any }) => {
    return { type: MERCHANT_QUOTATION_REQUEST, payload: data };
};

export const updateMerchantQuotation = (data: { [key: string]: any }) => {
    return { type: UPDATE_MERCHANT_QUOTATION_REQUEST, payload: data };
};

export const updateMerchantQuotationFromStep12 = (data: { [key: string]: any }) => {
    return { type: UPDATE_MERCHANT_QUOTATION_REQUEST2, payload: data };
};

export const getMerchantQuotationSteps = () => {
    return { type: GET_MERCHANT_STEP_REQUEST };
};
export const setMerchantQuotationSteps = (data: { [key: string]: any }) => {
    return { type: SET_MERCHANT_STEP_REQUEST, payload: data };
};
export const exitCallbackModalHandle = () => {
    return { type: EXIT_CALLBACK_MODAL };
};
export const userDetailsAction = (data: object) => {
    return { type: USER_DETAILS, payload: data };
};

export const createKYCAction = (data: any) => {
    return { type: CREATE_KYC_REQUEST, payload: data };
};

export const saveOnBoardingFormActions = (data: any) => {
    return { type: SAVE_ONBOARDING_FORM, payload: data };
};

export const updatePurchaseMetaData = (data: any) => {
    return {
        type: UPDATE_QUOTATION_PURCHASE_METADATA,
        payload: data,
    };
};

// Verify Phone Login - Custom Modal
export const sendOtpPhoneLogin = (data: { [key: string]: any }) => {
    return {
        type: SEND_OTP_PHONE_LOGIN_REQUEST,
        payload: data,
    };
};

export const verifyOtpPhoneLogin = (data: { [key: string]: any }) => {
    return {
        type: VERIFY_OTP_PHONE_LOGIN_REQUEST,
        payload: data,
    };
};

export const resendOtpPhoneLogin = (data: { [key: string]: any }) => {
    return {
        type: SEND_RESEND_OTP_PHONE_LOGIN_REQUEST,
        payload: data,
    };
};

export const userLogoutResetSMEDetails = () => {
    return {
        type: USER_LOGOUT_RESET_SME_DETAILS,
    };
};

export const changeInsuranceType = (data: string) => {
    return {
        type: CHANGE_INSURANCE_TYPE,
        payload: data,
    };
};

export const openSpecialRequirement = () => {
    return {
        type: SPECIAL_REQUIREMENT,
    };
};

export const resetMerchantStepDetails = () => {
    return {
        type: RESET_MERCHANT_STEP_DETAILS,
    };
};

export const editPhoneNumber = () => {
    return {
        type: EDIT_OTP_PHONE_NUMBER,
    };
};

export const clearEditPhoneNumber = () => {
    return {
        type: EDIT_OTP_PHONE_NUMBER_CLEAR,
    };
};
