// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__-Zk8c {
  display: flex;
  padding: var(--3, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--4, 32px);
  flex-shrink: 0;
}
.style_main__-Zk8c .style_container__geWd\\+ {
  display: flex;
  width: 1120px;
  padding: var(--3, 24px);
  flex-direction: column;
  gap: var(--4, 32px);
  flex-shrink: 0;
  border-radius: var(--none, 0px);
}

.style_form__YKLaL {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
}`, "",{"version":3,"sources":["webpack://./src/pages/V2Quote/PaymentSucess/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,+BAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAF","sourcesContent":[".main {\n  display: flex;\n  padding: var(--3, 24px);\n  flex-direction: column;\n  align-items: center;\n  gap: var(--4, 32px);\n  flex-shrink: 0;\n\n  .container {\n    display: flex;\n    width: 1120px;\n    padding: var(--3, 24px);\n    flex-direction: column;\n    gap: var(--4, 32px);\n    flex-shrink: 0;\n    border-radius: var(--none, 0px);\n  }\n}\n\n.form{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__-Zk8c`,
	"container": `style_container__geWd+`,
	"form": `style_form__YKLaL`
};
export default ___CSS_LOADER_EXPORT___;
