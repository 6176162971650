import { Button } from 'react-bootstrap';
import style from './style.module.scss';

const DummyPage = () => {
    const getRandomName = () => {
        const firstNames = ['John', 'Jane', 'Alex', 'Emily', 'Chris', 'Katie', 'Michael', 'Laura', 'David', 'Sarah'];
        const lastNames = ['Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez'];
        const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
        const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
        return `${firstName} ${lastName}`;
    };

    const getRandomEmail = (fullName: string) => {
        const emailProviders = ['example.com', 'mail.com', 'test.com', 'domain.com'];
        const provider = emailProviders[Math.floor(Math.random() * emailProviders.length)];
        const emailHandle = fullName.toLowerCase().replace(' ', '.');
        return `${emailHandle}@${provider}`;
    };

    const getRandomPhone = () => {
        const phonePrefix = '91'; // Indian phone number prefix
        const phoneNumber = Math.floor(1000000000 + Math.random() * 9000000000).toString();
        return `${phonePrefix}${phoneNumber}`;
    };

    const createAccount = () => {
        const fullName = getRandomName();
        const email = getRandomEmail(fullName);
        const phone = getRandomPhone();

        let jsonData = {
            fullName: fullName,
            email: email,
            phone: phone,
        };

        // Convert jsonData to a JSON string
        const jsonString = JSON.stringify(jsonData);

        // Encode the JSON string to base64
        const payload = btoa(jsonString);

        // Open the URL with the encoded payload in a new tab
        window.open(
            `${process.env.REACT_APP_SME_URL}/create-account?channel=vanghee&payload=${payload}`,
            '_blank',
        );
    };

    return (
        <section>
            <div className={style.mainContant}>
                <div>
                    <Button onClick={createAccount}>Create Account</Button>
                </div>
            </div>
        </section>
    );
};

export default DummyPage;
