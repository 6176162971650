import { call, delay, put, select } from 'redux-saga/effects';
import axios from '../../axiosConfig';
import {
    CREATE_KYC_ERROR,
    CREATE_KYC_SUCCESS,
    GET_MERCHANT_STEP_ERROR,
    GET_MERCHANT_STEP_SUCCESS,
    GO_TO_NEXT_STEP,
    goToPrevStep,
    MERCHANT_QUOTATION_ERROR,
    MERCHANT_QUOTATION_SUCCESS,
    SEND_OTP_PHONE_LOGIN_ERROR,
    SEND_OTP_PHONE_LOGIN_SUCCESS,
    SET_MERCHANT_STEP_ERROR,
    SET_MERCHANT_STEP_REQUEST,
    SET_MERCHANT_STEP_SUCCESS,
    SPECIAL_REQUIREMENT,
    UPDATE_MERCHANT_QUOTATION_ERROR,
    UPDATE_MERCHANT_QUOTATION_ERROR2,
    UPDATE_MERCHANT_QUOTATION_SUCCESS,
    UPDATE_MERCHANT_QUOTATION_SUCCESS2,
    VERIFY_OTP_PHONE_LOGIN_ERROR,
    VERIFY_OTP_PHONE_LOGIN_SUCCESS,
} from '../Actions/SMEActions';
import { NavigateFunction } from 'react-router-dom';
import { DecryptData, EncryptData } from '../../common/CryptoJSToken';
import { GET_USER_FOUND, GET_USER_NOT_FOUND } from '../Actions/AuthActions';
import { UPDATE_USER_DATA_SUCCESS } from '../Actions/UserProfileAction';
import { TOAST_SHOW } from '../Actions/ToastAction';
import { SAVE_USER_STATE_ACTIONS } from '../Actions/SessionAction';
import { shouldProcessLinkClick } from 'react-router-dom/dist/dom';
import { OPEN_POPUP_TALK_TO_EXPERT } from '../Actions/PopupActions';

export function* SaveMerchantQuotationSaga(actions: any): unknown {
    const { stopNext = false, quoteId, ...payload } = actions.payload;
    try {
        const response = yield call(
            axios.post,
            `v1/merchant-cover/quotes`,
            actions.payload,
        );
        const { data, success } = response?.data;

        const { SMEDetails } = yield select();
        const { currentStep } = SMEDetails;

        if (success) {
            yield put({
                type: MERCHANT_QUOTATION_SUCCESS,
                payload: data,
            });

            yield put({
                type: SET_MERCHANT_STEP_REQUEST,
                payload: {
                    requestMetadata: actions.payload,
                    responseMetadata: data,
                    stepNumber: stopNext ? currentStep - 1 : currentStep,
                },
            });
        }
    } catch (err: any) {
        yield put({
            type: MERCHANT_QUOTATION_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        if (err?.response?.status === 406) {
            yield put({
                type: OPEN_POPUP_TALK_TO_EXPERT,
                payload: true,
            });
        }
        console.log(err);
    }
}

export function* UpdateMerchantQuotationSaga(actions: any): unknown {
    const { stopNext=false, quoteId, ...payload } = actions.payload;

    try {
        const response = yield call(
            axios.put,
            `v1/merchant-cover/quotes/quotes-update/${quoteId}`,
            payload,
        );
        const { data, success } = response?.data;

        const { SMEDetails } = yield select();
        const { currentStep } = SMEDetails;

        if (success) {
            yield put({
                type: UPDATE_MERCHANT_QUOTATION_SUCCESS,
                payload: data,
            });

            yield put({
                type: SET_MERCHANT_STEP_REQUEST,
                payload: {
                    requestMetadata: payload,
                    responseMetadata: data,
                    stepNumber: stopNext ? currentStep - 1 : currentStep,
                },
            });
        }
    } catch (err: any) {
        yield put({
            type: UPDATE_MERCHANT_QUOTATION_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        if (err?.response?.status === 406) {
            yield put({
                type: OPEN_POPUP_TALK_TO_EXPERT,
                payload: true,
            });
        }
        console.log(err);
    }
}

export function* UpdateMerchantQuotationSaga2(actions: any): unknown {
    const { stopNext, quoteId, ...payload } = actions.payload;

    try {
        const response = yield call(
            axios.put,
            `v1/merchant-cover/quotes/quotes-update/${quoteId}`,
            payload,
        );
        const { data, success } = response?.data;

        const { SMEDetails } = yield select();
        const { currentStep } = SMEDetails;

        if (success) {
            yield put({
                type: UPDATE_MERCHANT_QUOTATION_SUCCESS2,
                payload: data,
            });

            yield put({
                type: SET_MERCHANT_STEP_REQUEST,
                payload: {
                    requestMetadata: payload,
                    responseMetadata: data,
                    stepNumber: stopNext ? currentStep - 1 : currentStep,
                },
            });

            // Wait for the updated step to reflect in the store
            if (stopNext) {
                // Introduce a delay of 3 seconds before proceeding
                yield delay(3000);

                const updatedSMEDetails = yield select((state) => state.SMEDetails);
                const currentStep = updatedSMEDetails.currentStep; // Ensure step is updated

                yield put(goToPrevStep());
            }
        }
    } catch (err: any) {
        yield put({
            type: UPDATE_MERCHANT_QUOTATION_ERROR2,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        if (err?.response?.status === 406) {
            yield put({
                type: OPEN_POPUP_TALK_TO_EXPERT,
                payload: true,
            });
        }
        console.log(err);
    }
}

export function* getMerchantQuotationStepSaga(): unknown {
    try {
        const response = yield call(axios.get, `v1/step`);
        const { data, success } = response?.data;

        if (success) {
            yield put({
                type: GET_MERCHANT_STEP_SUCCESS,
                payload: data,
            });
        }
    } catch (err) {
        yield put({
            type: GET_MERCHANT_STEP_ERROR,
        });
        console.log(err);
    }
}

export function* setMerchantQuotationStepSaga(action: any): unknown {
    try {
        const response = yield call(axios.put, `v1/step`, action.payload);
        const { data, success } = response?.data;
        if (success) {
            yield put({
                type: SET_MERCHANT_STEP_SUCCESS,
                payload: data,
            });
        }
    } catch (err) {
        yield put({
            type: SET_MERCHANT_STEP_ERROR,
        });
        console.log(err);
    }
}

export function* createKYCSaga(actions: any): unknown {
    try {
        const response = yield call(
            axios.post,
            `v1/kyc/smeCreate`,
            actions.payload.formData,
        );

        const { data, success } = response?.data;

        const { SMEDetails } = yield select();
        const { currentStep, quotation, quotationDetails } = SMEDetails;

        if (success) {
            // call the api to initiate manual purchase
            try {
                const initateResponse = yield call(
                    axios.post,
                    `v1/purchases/initiate-manual-sme-purchase`,
                    actions.payload.manualPurchaseData,
                );

                const { success: initateSuccess } = initateResponse?.data;
                if (initateSuccess) {
                    yield put({
                        type: CREATE_KYC_SUCCESS,
                        payload: data,
                    });

                    yield put({
                        type: SET_MERCHANT_STEP_REQUEST,
                        payload: {
                            requestMetadata: quotation,
                            responseMetadata: quotationDetails,
                            stepNumber: currentStep,
                        },
                    });
                }
            } catch (err: any) {
                yield put({
                    type: TOAST_SHOW,
                    payload: {
                        message:
                            err?.response?.data?.errors?.Error?.message ||
                            'Something went wrong',
                        severity: 'danger',
                        show: true,
                    },
                });
                console.log(err);
            }
        }
    } catch (err: any) {
        yield put({
            type: CREATE_KYC_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        console.log(err);
    }
}

export function* sendOtpPhoneLoginSaga(actions: any): unknown {
    try {
        const response = yield call(axios.post, `v1/otp/send`, {
            mobile: actions?.payload?.mobile ?? '',
        });

        const { request_id, isNewUser, type } = response?.data?.data;
        if (type === 'success') {
            yield put({
                type: SEND_OTP_PHONE_LOGIN_SUCCESS,
                payload: {
                    request_id,
                    isNewUser,
                },
            });
        }
    } catch (err: any) {
        yield put({
            type: SEND_OTP_PHONE_LOGIN_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        console.log(err);
    }
}

export function* resendOtpPhoneLoginSaga(actions: any): unknown {
    try {
        const response = yield call(
            axios.post,
            `v1/otp/resend`,
            actions.payload,
        );

        const { request_id, type } = response?.data;
        if (type === 'success') {
            yield put({
                type: SEND_OTP_PHONE_LOGIN_SUCCESS,
                payload: {
                    request_id,
                },
            });
        }
    } catch (err: any) {
        yield put({
            type: SEND_OTP_PHONE_LOGIN_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.data?.errors?.Error?.message ||
                    'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        console.log(err);
    }
}

export function* verifyOtpPhoneLoginSaga(actions: any): unknown {
    try {
        const {
            mainPayload,
            verifyPhoneLoginPayload,
            dispatchPayload,
            phoneNumberPayload,
        } = actions.payload;
        const response = yield call(axios.post, `v1/otp/verify`, mainPayload);
        const { data, success } = response?.data;

        if (success) {
            const payloadForVerifyPhoneLogin = {
                token: data?.message,
                requestId: mainPayload?.requestId ?? '',
                ...verifyPhoneLoginPayload,
            };

            dispatchPayload.dispatch(
                dispatchPayload.actionTrigger(payloadForVerifyPhoneLogin),
            );

            yield put({
                type: VERIFY_OTP_PHONE_LOGIN_SUCCESS,
                payload: data,
            });
        }
    } catch (err: any) {
        yield put({
            type: VERIFY_OTP_PHONE_LOGIN_ERROR,
        });
        yield put({
            type: TOAST_SHOW,
            payload: {
                message:
                    err?.response?.status === 406
                        ? 'Incorrect OTP'
                        : err?.response?.data?.errors?.Error?.message ||
                          'Something went wrong',
                severity: 'danger',
                show: true,
            },
        });
        console.log(err);
    }
}
