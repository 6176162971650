// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    /* height: 100vh; */
    height: 62vh;
    /* width: 100vw; */
    width: 56vw;
    background-color: rgba(255, 255, 255, .9);
}

.container-cropper {
    height: 100%;
    /* width: 800px; */
    /* margin: auto; */
    padding: 0;
}

.container-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
}

.cropper {
    height: 100%;
    position: relative;
}

.container-cropper .slider {
    padding: 15px 0;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;
    position: static;
    background-color: transparent;
}

.container-cropper .slider:before {
    display: none;
}

.popup-container {
    /* position: fixed; */
    /* top: 0; */
    /* eft: 0; */
    /* right: 0; */
    /* bottom: 0; */
    /* z-index: 1000; */
    overflow: hidden;
    width: 100%;
    margin: auto;
    /* padding: 25px; */
}

.MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
    margin-top: -9px !important;
}`, "",{"version":3,"sources":["webpack://./src/common/ImageCropPopup/ImageCropPopup.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,2BAA2B;AAC/B","sourcesContent":[".popup {\n    /* height: 100vh; */\n    height: 62vh;\n    /* width: 100vw; */\n    width: 56vw;\n    background-color: rgba(255, 255, 255, .9);\n}\n\n.container-cropper {\n    height: 100%;\n    /* width: 800px; */\n    /* margin: auto; */\n    padding: 0;\n}\n\n.container-buttons {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    outline: none;\n    border: none;\n}\n\n.cropper {\n    height: 100%;\n    position: relative;\n}\n\n.container-cropper .slider {\n    padding: 15px 0;\n    display: flex;\n    align-items: center;\n    margin: auto;\n    width: 60%;\n    position: static;\n    background-color: transparent;\n}\n\n.container-cropper .slider:before {\n    display: none;\n}\n\n.popup-container {\n    /* position: fixed; */\n    /* top: 0; */\n    /* eft: 0; */\n    /* right: 0; */\n    /* bottom: 0; */\n    /* z-index: 1000; */\n    overflow: hidden;\n    width: 100%;\n    margin: auto;\n    /* padding: 25px; */\n}\n\n.MuiSlider-thumb {\n    width: 20px !important;\n    height: 20px !important;\n    margin-top: -9px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
