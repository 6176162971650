// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__q8Q\\+U .style_contentBanner__T-KBn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background: #ffffff;
  height: 386px;
}
.style_main__q8Q\\+U .style_contentBanner__T-KBn .style_imageBox__6bmpo img {
  height: 291px;
  height: 291px;
}
.style_main__q8Q\\+U .style_contentBanner__T-KBn .style_Content__nTuZI h6 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #292c4e;
}
.style_main__q8Q\\+U .style_contentBanner__T-KBn .style_Content__nTuZI p {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #53556e;
}
.style_main__q8Q\\+U .style_contentBanner__T-KBn .style_Content__nTuZI button {
  border-radius: 27px;
  padding: 10px 15px;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  background: #7743dc;
  color: #ffffff;
  border: none;
}
.style_main__q8Q\\+U .style_contentBanner__T-KBn .style_Content__nTuZI button:hover {
  background: #fd7350;
}`, "",{"version":3,"sources":["webpack://./src/components/GetRecommendationSection/style.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AAEM;EACE,aAAA;EACA,aAAA;AAAR;AAKM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;AAHR;AAKM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;AAHR;AAKM;EACE,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;AAHR;AAIQ;EACE,mBAAA;AAFV","sourcesContent":[".main {\n  .contentBanner {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 50px;\n    background: #ffffff;\n    height: 386px;\n    .imageBox {\n      img {\n        height: 291px;\n        height: 291px;\n      }\n    }\n\n    .Content {\n      h6 {\n        font-size: 32px;\n        font-weight: 700;\n        line-height: 44px;\n        letter-spacing: -0.02em;\n        text-align: left;\n        color: #292c4e;\n      }\n      p {\n        font-size: 24px;\n        font-weight: 400;\n        line-height: 33px;\n        letter-spacing: -0.02em;\n        text-align: left;\n        color: #53556e;\n      }\n      button {\n        border-radius: 27px;\n        padding: 10px 15px;\n        gap: 10px;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        letter-spacing: 0.04em;\n        background: #7743dc;\n        color: #ffffff;\n        border: none;\n        &:hover {\n          background: #fd7350;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__q8Q+U`,
	"contentBanner": `style_contentBanner__T-KBn`,
	"imageBox": `style_imageBox__6bmpo`,
	"Content": `style_Content__nTuZI`
};
export default ___CSS_LOADER_EXPORT___;
