// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__mH05I {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 20px 20px;
  background: #f5f2fc;
}
.style_main__mH05I .style_content__HYbze {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentFeature/PaymentDetailsCard/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,mBAAA;AACF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AAAJ","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  border-radius: 0px 0px 20px 20px;\n  background: #f5f2fc;\n  .header {\n  }\n  .content {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__mH05I`,
	"content": `style_content__HYbze`
};
export default ___CSS_LOADER_EXPORT___;
