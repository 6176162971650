// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__nJ4Xd {
  border: 1px solid #f5f2fc;
  background: #fff;
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.style_main__nJ4Xd .style_key__Sm4S9 {
  color: #292c4e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
}
.style_main__nJ4Xd .style_value__hTLL\\+ {
  color: #696969;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentFeature/PaymentInfoCard/InfoRowComponent/style.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AAEJ;AAAE;EACE,cAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,0BAAA;AAEJ","sourcesContent":[".main {\n  border: 1px solid #f5f2fc;\n  background: #fff;\n  padding: 12px 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  .key {\n    color: #292c4e;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 160%; /* 25.6px */\n    text-transform: capitalize;\n  }\n  .value {\n    color: #696969;\n    text-align: right;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%; /* 25.6px */\n    text-transform: capitalize;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__nJ4Xd`,
	"key": `style_key__Sm4S9`,
	"value": `style_value__hTLL+`
};
export default ___CSS_LOADER_EXPORT___;
