// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__L8c0s {
  display: flex;
  flex-direction: column;
  padding: 50px 15px;
  gap: 5px;
  border-radius: 8px;
  background: #f5f2fc;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  align-items: center;
  cursor: pointer;
}
.style_main__L8c0s .style_icon__C49vA {
  width: 56px;
  height: 56px;
}
.style_main__L8c0s .style_text__8ilYK {
  text-align: center;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
}

.style_selectedCard__R9xIQ {
  border: 3px solid #7743dc !important;
}`, "",{"version":3,"sources":["webpack://./src/components/IndustryCard/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,QAAA;EACA,kBAAA;EACA,mBAAA;EAGA,+CAAA;EACA,mBAAA;EACA,eAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAIA;EACE,oCAAA;AADF","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  padding: 50px 15px;\n  gap: 5px;\n  border-radius: 8px;\n  background: #f5f2fc;\n  //   box-shadow: 0px 1px 5px 0px #0000001F;\n  //   box-shadow: 0px 2px 2px 0px #00000024;\n  box-shadow: 0px 3px 1px -2px #00000033;\n  align-items: center;\n  cursor: pointer;\n  .icon {\n    width: 56px;\n    height: 56px;\n  }\n\n  .text {\n    text-align: center;\n    height: 20px;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 20px;\n    letter-spacing: 0px;\n  }\n}\n.selectedCard {\n  border: 3px solid #7743dc !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__L8c0s`,
	"icon": `style_icon__C49vA`,
	"text": `style_text__8ilYK`,
	"selectedCard": `style_selectedCard__R9xIQ`
};
export default ___CSS_LOADER_EXPORT___;
