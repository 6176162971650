// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__Z2Mq5 {
  border-radius: 0px 0px 20px 20px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.style_main__Z2Mq5 h3 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 38.4px */
}
.style_main__Z2Mq5 button {
  color: #292c4e;
  background: #ffffff;
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentFeature/PaymentInfoHeader/style.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAEJ;AAAE;EACE,cAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,mBAAA;AAEJ","sourcesContent":[".main {\n  border-radius: 0px 0px 20px 20px;\n  padding: 24px;\n  display: flex;\n  justify-content: space-between;\n  h3 {\n    color: #fff;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 160%; /* 38.4px */\n  }\n  button {\n    color: #292c4e;\n    background: #ffffff;\n    display: inline-flex;\n    padding: 10px 20px;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    border: none;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__Z2Mq5`
};
export default ___CSS_LOADER_EXPORT___;
