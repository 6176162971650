import { Routes, Route, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import SMELayout from '../common/SMELayout';
import { IReduxState } from '../utils/types';
import { useSelector } from 'react-redux';
import CustomLoader from '../common/Loader/CustomLoader';
import AboutUs from '../pages/AboutUs';
import TermsConditions from '../pages/Terms';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import DummyPage from '../pages/DummyPage';
import GrievanceRedressal from '../pages/GrievanceRedressal';
const SMEOnboarding = lazy(() => import('../pages/SMEOnboarding'));
const SMEStockInsurance = lazy(() => import('../pages/SMEStockInsurance'));
const SMEFireInsurance = lazy(() => import('../pages/SMEFireInsurance'));
const SMELanding = lazy(() => import('../pages/SMELanding'));
const SMEAboutUs = lazy(() => import('../pages/SMEAboutUs'));
const SMEBuyInsurance = lazy(() => import('../pages/SMEBuyInsurance'));
const SMENews = lazy(() => import('../pages/SMENews'));
const ThirdPartyCreateAccountPage = lazy(() => import('../pages/ThirdPartyCreateAccountPage'));

const smeRoutes = [
    {
        path: '/stock-insurance',
        component: SMEStockInsurance,
        isPrivate: false,
    },
    { path: '/fire-insurance', component: SMEFireInsurance, isPrivate: false },
    {
        path: '/buy-stock-insurance',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    {
        path: '/buy-stock-insurance/payment-success/:receiptId',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    {
        path: '/buy-stock-insurance/payment-failure/:receiptId',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    {
        path: '/buy-fire-insurance',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    {
        path: '/buy-fire-insurance/payment-success/:receiptId',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    {
        path: '/buy-fire-insurance/payment-failure/:receiptId',
        component: SMEBuyInsurance,
        isPrivate: true,
    },
    { path: '/sme-onboarding', component: SMEOnboarding, isPrivate: false },
    {
        path: '/about-us',
        component: SMEAboutUs,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/news',
        component: SMENews,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/company/terms',
        component: TermsConditions,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/grievance-redressal',
        component: GrievanceRedressal,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        isPrivate: false,
        exact: true,
    },
    { path: '/', component: SMELanding, isPrivate: false, exact: true },
    {
        path: '/create-account',
        component: ThirdPartyCreateAccountPage,
        isPrivate: false,
        exact: true,
        isThirdParty: true,
    },
    {
        path: '/dummy-page',
        component: DummyPage,
        isPrivate: false,
        exact: true,
        isThirdParty: true,
    }
];

const SMERoutes = () => {
    const { isLogin } = useSelector((state: IReduxState) => state.Auth);

    return (
        <Routes>
            {smeRoutes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        route.isPrivate && !isLogin ? (
                            <Navigate to="/" replace />
                        ) : (
                            route?.isThirdParty ? <Suspense
                                fallback={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '90vh',
                                        }}
                                    >
                                        <CustomLoader
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                }
                            >
                                <route.component />
                            </Suspense>
                                : <SMELayout isLoggedIn={isLogin}>
                                    <Suspense
                                        fallback={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '90vh',
                                                }}
                                            >
                                                <CustomLoader
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        }
                                    >
                                        <route.component />
                                    </Suspense>
                                </SMELayout>
                        )
                    }
                />
            ))}
        </Routes>
    );
};

export default SMERoutes;
