// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_app__njNnQ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}
.style_app__njNnQ .style_login_form__0UI5I {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.style_app__njNnQ .style_login_form__0UI5I h1 {
  text-align: center;
}
.style_app__njNnQ .style_login_form__0UI5I label {
  display: block;
  margin-bottom: 8px;
}
.style_app__njNnQ .style_login_form__0UI5I input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}
.style_app__njNnQ .style_login_form__0UI5I button {
  background-color: #69a6f6;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.style_app__njNnQ .style_login_form__0UI5I button:hover {
  background-color: #45a049;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminLogin/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uCAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,cAAA;EACA,kBAAA;AAAN;AAGI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AADN;AAII;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAFN;AAGM;EACE,yBAAA;AADR","sourcesContent":[".app {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  margin: 0;\n\n  .login_form {\n    width: 300px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n\n    h1 {\n      text-align: center;\n    }\n\n    label {\n      display: block;\n      margin-bottom: 8px;\n    }\n\n    input {\n      width: 100%;\n      padding: 8px;\n      margin-bottom: 16px;\n      box-sizing: border-box;\n    }\n\n    button {\n      background-color: #69a6f6;\n      color: white;\n      padding: 10px 15px;\n      border: none;\n      border-radius: 4px;\n      cursor: pointer;\n      &:hover {\n        background-color: #45a049;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `style_app__njNnQ`,
	"login_form": `style_login_form__0UI5I`
};
export default ___CSS_LOADER_EXPORT___;
