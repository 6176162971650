import { Action } from 'redux';
import { ICreateAccountPayload } from '../Sagas/AuthSagas';

// Action types for Vanghee API
export const CREATE_ACCOUNT_REQUEST: any = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS: string = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE: string = 'CREATE_ACCOUNT_FAILURE';

// Action creators

/**
 * Action creator to start the process of creating an account via Vanghee API.
 */
export const createAccountRequest = (data: ICreateAccountPayload) => ({
    type: CREATE_ACCOUNT_REQUEST,
    payload: data,
});

/**
 * Action creator to handle success response from Vanghee API.
 *
 * @param {any} response - The success response from the API.
 */
export const createAccountSuccess = (response: any) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    payload: response,
});

/**
 * Action creator to handle failure response from Vanghee API.
 *
 * @param {string} error - The error message from the API.
 */
export const createAccountFailure = (error: string) => ({
    type: CREATE_ACCOUNT_FAILURE,
    payload: error,
});
