// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_main__3GqAH {
  padding: 28px 76px;
  box-shadow: 1px 1px 4px 0px rgba(119, 67, 220, 0.3019607843);
}
.style_main__3GqAH h4 {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: var(--2);
  text-align: center;
  color: #774ad9;
}
.style_main__3GqAH p {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  letter-spacing: 0.150000006px;
  text-align: center;
  color: rgba(41, 44, 78, 0.6);
}
.style_main__3GqAH .style_body__5Wmqe {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.style_main__3GqAH .style_body__5Wmqe .style_footer__Jl1z\\+ {
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}
.style_main__3GqAH .style_body__5Wmqe .style_footer__Jl1z\\+ button {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  padding: 15px 100px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 9px 0px rgba(54, 16, 129, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/RequestCallbackl/CallbackModal/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,4DAAA;AACJ;AACI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;AACR;AACI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,6BAAA;EACA,kBAAA;EACA,4BAAA;AACR;AACI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACR;AAAQ;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;AAEZ;AADY;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kDAAA;AAGhB","sourcesContent":[".main {\n    padding: 28px 76px;\n    box-shadow: 1px 1px 4px 0px #7743dc4d;\n\n    h4 {\n        font-family: Nunito;\n        font-size: 20px;\n        font-weight: 800;\n        line-height: var(--2);\n        text-align: center;\n        color: #774ad9;\n    }\n    p {\n        font-family: Nunito;\n        font-size: 18px;\n        font-weight: 600;\n        line-height: 24.55px;\n        letter-spacing: 0.15000000596046448px;\n        text-align: center;\n        color: #292c4e99;\n    }\n    .body {\n        display: flex;\n        flex-direction: column;\n        gap: 25px;\n        .footer {\n            display: flex;\n            justify-content: center;\n            padding: 10px 10px;\n            button {\n                font-size: 18px;\n                font-weight: 400;\n                display: flex;\n                padding: 15px 100px;\n                align-items: center;\n                justify-content: center;\n                box-shadow: 0px 6px 9px 0px #36108133;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `style_main__3GqAH`,
	"body": `style_body__5Wmqe`,
	"footer": `style_footer__Jl1z+`
};
export default ___CSS_LOADER_EXPORT___;
