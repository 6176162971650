import { Fragment, useEffect, useState } from 'react';
import { IReduxState } from '../../utils/types';
import { useSelector } from 'react-redux';
import Header from '../../common/Layouts/Header';

const GrievanceRedressal = () => {
    const { isLogin } = useSelector((state: IReduxState) => state.Auth);

    const companyName = window.location.host.includes('sme')
        ? 'Covrzy'
        : 'Covrzy';

    return (
        <Fragment>
            {!isLogin && !window.location.host.includes('sme') && <Header />}
            <div className="container">
                <div className="hero-info terms-title text-center">
                    <h1>Grievance Redressal</h1>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ol type="I">
                            <div className="col-lg-12 mb-3">
                                <p className="term-content">
                                    We are committed to delivering outstanding
                                    service to all our clients. However, we
                                    understand that sometimes things might not
                                    go as planned. We genuinely value your
                                    feedback and are dedicated to addressing any
                                    issues you may encounter swiftly
                                </p>
                                <p className="term-content">
                                    If you have a concern, please reach out to
                                    us by emailing{' '}
                                    <strong>support@covrzy.com</strong> with
                                    your details.
                                </p>

                                <p className="term-content">
                                    You can also send us a letter at:
                                </p>

                                <p className="term-content">
                                    <strong>
                                        Bizcovr Insurance Broking Private
                                        Limited
                                    </strong>
                                    <br />
                                    3rd Floor, AI Mannar Building bearing,
                                    <br />
                                    777/E, 100 Feet Rd, above Titan World, HAL
                                    <br />
                                    2nd Stage, Doopanahalli, Indiranagar
                                    <br />
                                    Bengaluru, Karnataka 560008
                                </p>

                                <p className="term-content">
                                    Rest assured, we will acknowledge your
                                    grievance and take necessary action to
                                    resolve it within 14 working days of
                                    receiving your request.
                                </p>

                                <p className="term-content">
                                    Should you find the response from our
                                    Principal Officer unsatisfactory or if you
                                    require further assistance, you can escalate
                                    the matter to the Grievance Redressal Cell
                                    of the Insurance Regulatory Development
                                    Authority of India (IRDAI). Their contact
                                    details are as follows:
                                </p>

                                <p className="term-content">
                                    Sy.No.115/1, Financial District,
                                    <br />
                                    Nanakramguda,
                                    <br /> Gachibowli,
                                    <br /> Hyderabad – 500 032
                                </p>

                                <p className="term-content">
                                    You can also reach their Grievance Call
                                    Centre toll-free at 155255 or 1800 4254 732.
                                </p>
                            </div>
                        </ol>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default GrievanceRedressal;
